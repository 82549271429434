import React, { useState, useEffect } from "react"
import {
    apiUrl
} from "../../constants/api"
import "./collection-page.scss"
import _ from "lodash"
import CollectionPageContent from "./CollectionPageContent"

const CollectionPage = ({ collectionId }) => {
    const [collection, setCollection] = useState(false)
    const [loadingError, setLoadingError] = useState(false)

    useEffect(() => {
        if(collection === false) {
            fetch(`${apiUrl}/collection/public/${collectionId}`).then(resp => {
                if(resp.status === 200) {
                    resp.json().then(data => {
                        setCollection(data)
                    })
                } else {
                    setLoadingError("error loading artwork data")
                }
            })
        }
    })

    console.log("collection page")
    return (
        <div className='collection-page'>
            {loadingError ? (
                <p>{loadingError}</p>
            ) : (
                <React.Fragment>
                    {(collection) ? (
                        <CollectionPageContent collection={collection} />
                    ) : (
                        <div>
                        <p>Loading</p>
                        <p>Collection page for id: {collectionId}</p>
                        </div>

                    )}
                </React.Fragment>
                
            )}
        </div>
    )
}

export default CollectionPage