import React, { useState, useContext } from "react"
import {
    Input,
    Button,
    Label
} from "semantic-ui-react"
import "./tag-editor.scss"
import _ from "lodash"

const TagEditor = ({
    entity,
    context
}) => {
    const [newTagName, setNewTagName] = useState("")
    const entityState = useContext(context)
    const currentTags = _.get(entity, 'tags', [])
    return (
        <div className='tag-editor'>
            <h3>Tag editor</h3>
            <div>
            <Input
                size="small"
                placeholder="add tag..."
                value={newTagName}
                onChange={(e) => setNewTagName(e.target.value)}
                />
            <Button
                disabled={currentTags.includes(newTagName)}
                onPointerDown={() => {
                    console.log("add tag: ", newTagName)
                    entityState.actions.update({
                        ...entity,
                        tags: entity.tags.concat([newTagName])
                    })
                }}
                className='add-tag'
                size="tiny">
                Add
            </Button>
            </div>
            <div className='current-tags'>
                {currentTags.map(tag => {
                    return (
                        <Label key={tag}>
                            {tag}
                        </Label>
                    )
                })}
            </div>
        </div>
    )
}

export default TagEditor