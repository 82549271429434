import _ from 'lodash'
import React from 'react'
import {
    Table,
    Button
} from 'semantic-ui-react'

import dayjs from 'dayjs'

const VersionsList = ({
    entity,
    actions,
    versions,
    rollbackButton
}) => {
    var versionsSorted = versions.slice()
    versionsSorted = _.sortBy(versionsSorted, 'createdAt')
    return (
        <div className='versions-list'>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        {rollbackButton && (
                            <Table.HeaderCell>Reset to this version</Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {versionsSorted.map((version, i) => {
                        return (
                            <Table.Row key={version._id}>
                                <Table.Cell>{i}</Table.Cell>
                                <Table.Cell>{_.get(version, 'scriptName', '-')}</Table.Cell>
                                <Table.Cell>{dayjs(version.createdAt).format('dddd, MMMM D, YYYY h:mm A')}</Table.Cell>
                                {rollbackButton && (
                                    <Table.Cell>
                                        <Button size='tiny'
                                            onClick={() => {
                                                console.log(version)
                                                var rollbackEntity = {
                                                    ...version,
                                                    storeVersion: true
                                                }
                                                rollbackEntity['_id'] = entity._id
                                                rollbackEntity['name'] = entity.name
                                                delete rollbackEntity['createdAt']
                                                delete rollbackEntity['updatedAt']
                                                actions.update(rollbackEntity)
                                            }}
                                            >
                                            Reset to version
                                        </Button>
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </div>
    )
}

VersionsList.defaultProps = {
    rollbackButton: false
}

export default VersionsList