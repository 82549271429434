import React, { useState, useContext } from 'react'
import {
    Form,
    Input,
    Button,
    Message
} from 'semantic-ui-react'
import './edit-entity-form.css'
import _ from 'lodash'

const EditEntityForm = ({
    context,
    entity
}) => {
    const [newName, setNewName] = useState(entity.name)
    const entityState = useContext(context)
    var inputClassName = ""
    if(newName !== entity.name) {
        inputClassName += 'edited'
    }
    const updating = _.get(entity, 'updating', false)
    const updateError = _.get(entity, 'updateError', false)
    return (
        <div className='edit-entity-form'>
            <div className='ui form'>
                <Form.Field>
                    <label>Name</label>
                    <Input
                        placeholder='name...'
                        value={newName}
                        className={inputClassName}
                        onChange={(e) => setNewName(e.target.value)}
                        />
                </Form.Field>
                <Button
                    onClick={() => {
                        entityState.actions.update({
                            ...entity,
                            storeVersion: true,
                            name: newName
                        })
                    }}
                    >
                    Save
                </Button>
                {updating && (
                    <p>updating script</p>
                )}
                {updateError && (
                    <Message negative>
                        {updateError}
                    </Message>
                )}
            </div>
        </div>
    )
}

export default EditEntityForm