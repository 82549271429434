import types from './actionTypes'
import _ from 'lodash'

const initialState = {
    user_id: false,
    authenticated: false,
    authenticating: false,
    authenticationError: false,
    authCheck: false,
    email: false,
    name: false,
    creatingAccount: false,
    createAccountError: false,
    refreshingToken: false,
    tokenRefreshError: false,
    token: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case types.USER_CREATE_ACCOUNT:
            return {
                ...state,
                creatingAccount: true
            }
        case types.USER_CREATE_ACCOUNT_ERROR:
            return {
                ...state,
                creatingAccount: false,
                createAccountError: action.payload
            }
        case types.USER_CREATE_ACCOUNT_CONFIRMED:
            return {
                ...state,
                ...action.payload,
                authenticated: true,
                authenticating: false,
                signingUp: false,
                authenticationError: false,
                creatingAccount: false,
                createAccountError: false
            }
        case types.USER_LOG_IN:
                return {
                    ...state,
                    authenticating: true
                }
        case types.USER_LOG_OUT:
            return {
                ...state,
                ...initialState
            }
        case types.USER_LOG_IN_CONFIRMED:
            return {
                ...state,
                ...action.payload,
                authenticated: true,
                authenticating: false,
                signingUp: false,
                authenticationError: false
            }
        case types.USER_LOG_IN_ERROR:
            return {
                ...state,
                authenticationError: action.payload,
                authenticated: false,
                authenticating: false
            }
        case types.USER_CHECK_AUTH_SUCCESSFUL:
            return {
                ...state,
                authCheck: 'auth check successful'
            }
        case types.USER_CHECK_AUTH_ERROR:
            return {
                ...state,
                authCheck: _.get(action, 'payload', 'user auth check error')
            }
        case types.USER_REFRESH_TOKEN:
            return {
                ...state,
                refreshingToken: true,
            }
        case types.USER_REFRESH_TOKEN_SUCCESSFUL:
            return {
                ...state,
                ...action.payload,
                refreshingToken: false,
            }
        case types.USER_REFRESH_TOKEN_ERROR:
            return {
                ...state,
                tokenRefreshError: true,
                refreshingToken: false
            }
        case types.USER_CHECK_TOKEN_SUCCESSFUL:
            return {
                ...state,
                authenticated: true,
                authenticating: false,
                signingUp: false,
                authenticationError: false,
                ...action.payload
            }
        default:
            return state
    }
}

export {
    initialState,
    reducer
}