import { apiUrl } from './defaults'

export const createAccount = user => {
    return fetch(apiUrl + '/create-account', {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
            'Content-Type':"application/json"
        },
        credentials: 'include'
    })
}

export const login = user => {
    return fetch(apiUrl + '/login', {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
            "Content-Type":"application/json"
        },
        credentials: 'include'
    })
}

export const checkAuth = user => {
    return fetch(apiUrl +'/auth-check', {
        headers: {
            'Authorization': `Bearer ${user.token}`
        },
        credentials: 'include'
    })
}

export const refreshToken = user => {
    return fetch(apiUrl + '/refresh-token', {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    })
}

export const checkToken = () => {
    return fetch(apiUrl + '/check-token', {
        credentials: 'include'
    })
}

export const logOut = () => {
    return fetch(apiUrl + '/log-out', {
        method: "POST",
        credentials: 'include'
    })
}