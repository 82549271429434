import React, { createContext, useEffect, useReducer, useState } from 'react'
import { reducer, initialState } from './reducer'
import { applyMiddleware } from './middleware'
import { useActions } from './actions'
import dayjs from 'dayjs'
const UserContext = createContext()



const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [checkedLocalToken, setCheckedLocalToken] = useState(false)

    const actions = useActions(applyMiddleware(dispatch))
    const user = {
        ...state,
        actions
    }
    useEffect(() => {
        if(user.authenticated && user.refreshingToken === false) {
            const expiringIn = dayjs(user.exp * 1000).diff(dayjs(), 'second')
            if(expiringIn < 30 && user.tokenRefreshError === false) {
                user.actions.refreshToken(user)
            }
        }
    }, [user])
    
    useEffect(() => {
        if(user.authenticated === false && checkedLocalToken === false) {
            setCheckedLocalToken(true)
            user.actions.checkToken()
        }
    }, [user, checkedLocalToken, setCheckedLocalToken])
    return (
        <UserContext.Provider
            value={user}
            >
            {children}
        </UserContext.Provider>
    )
}

export {
    UserContext,
    UserProvider
}