const EntityStorageState = ({
    entity,
    entityStore
}) => {
    return (
        <div className='entity-storage-state'>

        </div>
    )
}

export default EntityStorageState