import React from "react"
import ReactDOMServer from "react-dom/server"
import PageContents from "../components/ArtworkPage/PageContents"
import _ from "lodash"
import format from "xml-formatter"

function generatePreview(artwork, collection) {
    var collectionName = collection.name
    var pageContentString = ReactDOMServer.renderToString(
        <div className='artwork-page'>
        <PageContents artwork={artwork} collection={collection} />
        </div>
    )
return `{% put title %}${artwork.name}{% endput %}
{% put collectionLink %}${_.get(collection, "pageLink", "https://hyperobjects.design/gallery")}{% endput %}
{% put collectionName %}${collectionName}{% endput %}
<div id="artwork-page-container" artworkId="${artwork._id}" >
${format(pageContentString)}
<script>
window.onload = function() {
    window.renderArtworkPage("artwork-page-container")
}
</script>`
}

export default generatePreview