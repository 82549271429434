import React, { useState, useEffect } from "react"
import {
    apiUrl
} from "../../constants/api"
import "./artwork-page.scss"
import _ from "lodash"
import PageContents from "./PageContents"


const ArtworkPage = ({
    artworkId
}) => {
    const [artwork, setArtwork] = useState(false)
    const [collection, setCollection] = useState(false)
    const [loadingError, setLoadingError] = useState(false)
    
    
    useEffect(() => {
        if(artwork === false) {
            fetch(`${apiUrl}/artwork/public/${artworkId}`).then(resp => {
                if(resp.status === 200) {
                    resp.json().then(data => {
                        setArtwork(data)
                    })
                } else {
                    setLoadingError("error loading artwork data.")
                }
            })
        }
        if(_.isObject(artwork) && collection === false) {
            var artworkPrimaryCollection = _.get(artwork, "collections[0]", false)
            if(artworkPrimaryCollection) {
                fetch(`${apiUrl}/collection/find/${artworkPrimaryCollection}`).then(resp => {
                    if(resp.status === 200) {
                        resp.json().then(data => {
                            setCollection(data)
                        })
                    } else {
                        setCollection("collection not found")
                    }
                })
            }
        }
    })
    
    return (
        <div className='artwork-page'>
            {loadingError ? (
                <p>{loadingError}</p>
            ) : (
                <React.Fragment>
                    {(artwork && collection) ? (
                        <PageContents artwork={artwork} collection={collection} />
                    ) : (
                        <div>
                        <p>Loading</p>
                        <p>Artwork page for id: {artworkId}</p>
                        </div>

                    )}
                </React.Fragment>
                
            )}
        </div>
    )
}

export default ArtworkPage