import React, { useEffect, useState, useRef } from "react"
import {
    Input,
    Button
} from "semantic-ui-react"
import {
    DragDropContext,
    Droppable,
    Draggable
} from "react-beautiful-dnd"
import _ from "lodash"
import useComponentSize from "@rehooks/component-size"

// https://codesandbox.io/s/k260nyxq9v?file=/index.js:373-1067

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const grid = 8;
  
  const getItemStyle = (isDragging, draggableStyle, lineWrap, width) => {
    const lineWrapStyle = !lineWrap ? {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: 30
    } : {}
    return {
        position: "relative",
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 0.5,
        margin: `0 0 0 0`,
        // change background colour if dragging
        background: isDragging ? "rgba(240,240,240,0.4)" : "transparent",
      
        // styles we need to apply on draggables
        ...draggableStyle,
        ...lineWrapStyle
    }
}
  
  const getListStyle = (isDraggingOver, width) => ({
    border: `1px solid ${isDraggingOver ? "rgb(220,220,220)" : "transparent"}`,
    padding: 3,
    position: "relative",
    top: -3,
    left: -3,
    width: width
  });

const EditableList = ({
        items,
        callback,
        lineWrap = false,
        width = 450,
        fluid = true
    }) => {
    const ref = useRef(null)
    const containerSize = useComponentSize(ref)
    const _width = fluid ? containerSize.width : width
    const [itemsWithId, setItemsWithId] = useState([])
    useEffect(() => {
        if(!_.isEqual(items, itemsWithId.map(p => p.item))) {
            setItemsWithId(items.map((p, i) => {
                return {
                    item: p,
                    id: `item-${i}`
                }
            }))
        }
    })
    const [newItemName, setNewItemName] = useState("")
    return (
        <div className='editable-list' ref={ref}>
            <DragDropContext onDragEnd={(result) => {
                var newList = reorder(itemsWithId, result.source.index, result.destination.index)
                setItemsWithId(newList)
                callback(newList.map(p => p.item))
            }}>
                <Droppable droppableId="editable-list">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver, _width)}
                        className='ui celled list'
                        >
                                {itemsWithId.map((item, i) => {
                                    return (
                                        <Draggable
                                            key={`${item.id}`}
                                            draggableId={`${item.id}`}
                                            index={i}
                                            >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                        lineWrap,
                                                        _width
                                                        )}
                                                        className='ui list item'
                                                        >
                                                        {item.item}
                                                        <Button
                                                            icon="remove"
                                                            basic
                                                            size="tiny"
                                                            style={{
                                                                position: "absolute",
                                                                top: 3,
                                                                right: 3,
                                                                padding: 3
                                                            }}
                                                            circular
                                                            onPointerDown={() => {
                                                                var newItems = itemsWithId.filter((p, _i) => i !==  _i)
                                                                setItemsWithId(newItems)
                                                                callback(newItems.map(p => p.item))
                                                            }}
                                                            />
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                        
                                    )
                                })}
                                {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            <div style={{
                paddingLeft: 5,
                paddingRight: 10,
                width: _width
                }}>
            <Input size="mini"
                fluid
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
                action={(
                    <Button
                        onPointerDown={() => {
                            var newItems = itemsWithId.slice().concat([
                                {
                                    item: newItemName,
                                    id: `item-${itemsWithId.length}`
                                }
                            ])
                            setItemsWithId(newItems)
                            callback(newItems.map(p => p.item))
                        }}
                        size="tiny">
                        Add
                    </Button>
                )}
                />
            </div>
        </div>
    )
}

export default EditableList