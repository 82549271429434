import React from "react"
import {
    Grid,
    Image,
    Label,
    Button
} from "semantic-ui-react"
import ReactMarkdown from 'react-markdown'
import "./list-layout.scss"
import _ from "lodash"
import MediaViewer from "../../../MediaViewer"

const ListLayout = ({ items }) => {
    return (
        <div className='list-layout'>
             {items.map((item, item_i) => {
                return (
                    <div className='item' key={`${item.id}-${item_i}`}>
                    <Grid doubling columns={2}>
                        <Grid.Column>
                            <div className='description-column'>
                                <div className='top-content'>
                                    <a className='heading' href={item.pageLink}>
                                        <h2>
                                        {item.name}
                                        </h2>
                                    </a>
                                    <div className='excerpt'>
                                        <ReactMarkdown>
                                            {item.excerptMd}
                                        </ReactMarkdown> 
                                    </div>
                                </div>
                                <div className='bottom-bar'>
                                    {item.tags.map((tag, i) => {
                                        return (
                                            <a
                                                href={`/artwork/tag/${tag}`}
                                                key={`${tag}-${i}`}
                                                >
                                            <Label
                                                basic
                                                floated="right"
                                                color="black"
                                                size="tiny"
                                                >
                                                {tag}
                                            </Label>
                                            </a>
                                        )
                                    })}
                                    <a href={item.pageLink}>
                                        <Button floated="right"
                                            size="tiny"
                                            basic
                                            fluid
                                            color="black"
                                            className='open-button'
                                            icon="terminal"
                                            />
                                    </a>
                                </div>
                            </div>                        
                        </Grid.Column>
                        <Grid.Column>
                            <a href={item.pageLink}>
                            <MediaViewer
                                url={_.get(item, 'imageUrls[0]', "")}
                                />
                            </a>
                        </Grid.Column>
                    </Grid>
                    </div>
                )
            })}
        </div>
    )
}

export default ListLayout