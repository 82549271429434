import React, { useState } from "react"
import {
    useWindowWidth
  } from '@react-hook/window-size'
  import {
    Container,
    Grid,
    Label,
    List
} from "semantic-ui-react"
import ReactMarkdown from 'react-markdown'
import _ from "lodash"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import marked from "marked"
import MediaViewer from "../MediaViewer"

const viewBreakpoint = 768


const PageContents = ({ artwork, collection }) => {
    const windowWidth = useWindowWidth()
    const [animationSpeed, setAnimationSpeed] = useState(500)
    var collectionContent = (
        <React.Fragment>
        {_.isObject(collection) && (
            <div className='collection-sidebar'>
                <p>Part of:</p>
                <h3>{collection.name}</h3>
                <List>
                    {_.get(collection, 'cachedObjects', []).map(obj => {
                        var pageLink = _.get(obj, "pageLink", false)
                        return (
                            <List.Item key={obj._id}>
                                {pageLink ? (
                                    <a href={pageLink}>
                                        {obj.name}
                                    </a>
                                ) : (
                                    <React.Fragment>
                                        {obj.name}
                                    </React.Fragment>
                                )}
                                
                            </List.Item>
                        )
                    })}
                </List>
            </div>
        )}
        </React.Fragment>
    )

    var collectionCol = <Grid.Column width={4}>{collectionContent}</Grid.Column>

    const slickSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        easing: "ease-in-out",
        cssEase: "ease-in-out"
    }

    const authorLink = _.get(artwork, "authorLink", false)
    const authorName = _.get(artwork, "authorName", false)

    const tags = _.get(artwork, "tags", [])

    return (
        <React.Fragment>
            <div className='carousel-container'>
                <Slider {...slickSettings}>
                    {_.get(artwork, "imageUrls", []).map((img, i, a) => {
                        return (
                            <div key={i} className="carousel-slide">
                                <MediaViewer url={img} />
                                <div className='overlay'>
                                    <p>{`${i+1}/${a.length}`}</p>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
            <Container>
                <Grid columns={3} stackable centered>
                    {windowWidth >= viewBreakpoint && (
                        <Grid.Column width={3}>
                            <div className='sticky-col'>
                                {collectionContent}
                            </div>
                        </Grid.Column>
                    )}
                    
                    <Grid.Column width={8}>
                        <h1>{_.get(artwork, "name", "")}</h1>
                        <div className='artwork-description'>
                            <div dangerouslySetInnerHTML={{
                                __html: marked(_.get(artwork, "descriptionMd", ""))
                            }} />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div className='right-sidebar' style={{
                            position: windowWidth < viewBreakpoint ? "initial" : "-webkit-sticky",
                            position: windowWidth < viewBreakpoint ? "initial" : "sticky",
                        }}
                        >
                            {(authorLink && authorName) && (
                                    <div>
                                        <h4>Created by</h4>
                                        <Label size="tiny" as="a" href={authorLink}
                                            color="black"
                                            basic
                                            >
                                            {authorName}
                                        </Label>
                                    </div>
                            )}
                            {tags.length > 0 && (
                                <div className='tags' style={{marginTop: 20}}>
                                    <h4>Tags</h4>
                                    {tags.map((tag, i) => {
                                            return (
                                                <Label
                                                    href={`/artwork/tag/${tag}`}
                                                    key={`${tag}-${i}`}
                                                    basic
                                                    floated="right"
                                                    color="black"
                                                    size="tiny"
                                                    >
                                                    {tag}
                                                </Label>
                                            )
                                        })}
                                </div>
                            )}
                        </div>
                    </Grid.Column>
                    <div className='grid-doubling-visible'>
                        {collectionCol}
                    </div>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default PageContents