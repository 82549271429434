import React, { useState } from "react"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import { Responsive, WidthProvider } from 'react-grid-layout'
import _ from "lodash"
import "./grid-layout.scss"
import MediaViewer from "../MediaViewer"

const ResponsiveGridLayout = WidthProvider(Responsive);


const GridLayout = ({
    layouts = false,
    items,
    editable = false,
    layoutsCallback = () => {}
}) => {
    var _layouts = {}

    const largeCols = 4
    const smallCols = 2

    if(layouts === false) {
        const largeLayout = items.map((item, i) => {
            var x = i % largeCols
            var y = Math.floor(i/largeCols)
            return {
                i: item.id,
                x: x * 2,
                y: y * 2,
                w: 2,
                h: 2,
            }
            
        })

        const smallLayout = items.map((item, i) => {
            var x = i % smallCols
            var y = Math.floor(i/smallCols)
            return {
                i: item.id,
                x: x * 2,
                y: y * 2,
                w: 2,
                h: 2,
            }
        })

        _layouts = {
            lg: largeLayout,
            sm: smallLayout
        }
    } else {
        _layouts = layouts
    }
    
    return (
        <div className='grid-layout'>
            <ResponsiveGridLayout
                className="layout"
                layouts={_layouts}
                cols={8}
                rowHeight={100}
                isDraggable={editable}
                isResizable={editable}
                breakpoints={{lg: 768, sm: 0 }}
                onLayoutChange={(newLayout,  newLayouts) => {
                    layoutsCallback(newLayouts)
                }}
                cols={{lg: largeCols * 2, sm: smallCols * 2}}
                margin={[0, 0]}
                >
                {items.map(item => {
                    var contents = (
                        <div className='contents'>
                            <MediaViewer
                                url={_.get(item, "image", "")}
                                />
                            <div className='overlay'>
                                <h3>
                                    {item.name}
                                </h3>
                            </div>
                        </div>
                    )
                    return (
                        <div key={item.id} style={{overflow: "hidden"}} className='grid-card'>
                            {editable ? (
                                <React.Fragment>
                                    {contents}
                                </React.Fragment>
                            ) : (
                                <a href={item.link}>
                                    {contents}
                                </a>
                            )}
                        </div>
                    )
                })}
            </ResponsiveGridLayout>
        </div>
    )
}

export default GridLayout