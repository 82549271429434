import React from 'react'
import _ from 'lodash'
import {
    Message
} from 'semantic-ui-react'

const CreateAccountError = ({
    error
}) => {
    var body = null
    if(_.isString(error)) {
        body = (
            <Message negative>
                {error}
            </Message>
        )
    }
    if(_.isArray(error)) {
        body = (
            <React.Fragment>
                {error.filter(p => _.isString(p)).map(p => {
                    return (
                        <Message negative>
                            {p}
                        </Message>
                    )
                })}
            </React.Fragment>
        )
    }
    return (
        <div className='create-account-error'>
            {body}
        </div>
    )
}

export default CreateAccountError