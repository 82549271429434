export default {
    USER_LOG_IN: "USER_LOG_IN",
    USER_LOG_OUT: "USER_LOG_OUT",
    USER_LOG_IN_ERROR: "USER_LOG_IN_ERROR",
    USER_LOG_IN_CONFIRMED: "USER_LOG_IN_CONFIRMED",
    USER_LOG_OUT_CONFIRMED: "USER_LOG_OUT_CONFIRMED",
    
    USER_CREATE_ACCOUNT: "USER_CREATE_ACCOUNT",
    USER_CREATE_ACCOUNT_ERROR: "USER_CREATE_ACCOUNT_ERROR",
    USER_CREATE_ACCOUNT_CONFIRMED: "USER_CREATE_ACCOUNT_CONFIRMED",

    USER_REFRESH_TOKEN: "USER_REFRESH_TOKEN",
    USER_REFRESH_TOKEN_ERROR: "USER_REFRESH_TOKEN_ERROR",
    USER_REFRESH_TOKEN_SUCCESSFUL: "USER_REFRESH_TOKEN_SUCCESSFUL",

    USER_CHECK_AUTH: "USER_CHECK_AUTH",
    USER_CHECK_AUTH_ERROR: "USER_CHECK_AUTH_ERROR",
    USER_CHECK_AUTH_SUCCESSFUL: "USER_CHECK_AUTH_SUCCESSFUL",

    USER_CHECK_TOKEN: "USER_CHECK_TOKEN",
    USER_CHECK_TOKEN_SUCCESSFUL: 'USER_CHECK_TOKEN_SUCCESSFUL'
}