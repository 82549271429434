import React from "react"
import ReactDOMServer from "react-dom/server"
import CollectionPageContent from "../components/CollectionPage/CollectionPageContent"
import _ from "lodash"
import format from "xml-formatter"
function generateCollectionPreview(collection) {
    var collectionName = collection.name
    var pageContentString = ReactDOMServer.renderToString(
        <div className='collection-page'>
            <CollectionPageContent collection={collection} />
        </div>
    )
    return `{% put title %}${collectionName}{% endput %}
<div id="collection-page-container" collectionId=${collection._id}>
${format(pageContentString)}
</div>
<script>
window.onload = function() {
    window.renderCollectionPage("collection-page-container")
}
</script>
`
}

export default generateCollectionPreview