import React, { useState } from "react"
import {
    Table,
    Input,
    Icon,
    Button
} from "semantic-ui-react"
import _ from "lodash"
import "./data-table.scss"

const sampleData = [
    {
        name: "A",
        value: 1,
        label: "example"
    },{
        name: "B",
        value: 2,
        label: "tutorial"
    },{
        name: "C",
        value: 3,
        label: "description"
    }
]

const DataTable = ({
    data = sampleData,
    columns = false,
    title = false,
    columnLabels = false,
    search = false,
    link = false,
    selectable = false,
    onRowSelect = false
}) => {
    var [searchText, setSearchText] = useState("")
    var [sortColumn, setSortColumn] = useState(false)
    var [sortDirection, setSortDirection] = useState(false)
    var tableColumns = columns ? columns : _.keys(data[0])
    var filteredData = data.slice()
    if(searchText.length > 0) {
        filteredData = filteredData.filter(row => _.toLower(row[tableColumns[0]]).includes(_.toLower(searchText)))
    }
    if(sortColumn) {
        filteredData = _.sortBy(filteredData, sortColumn)
        if(sortDirection) {
            filteredData = filteredData.reverse()
        }
    }
    return (
        <div className='data-table'>
            <Table
                selectable={selectable || _.isFunction(onRowSelect)}
                fixed={searchText.length > 0}
                >
                <Table.Header>
                    {title && (
                        <Table.Row>
                            <Table.HeaderCell colSpan={tableColumns.length}>
                                {title}
                            </Table.HeaderCell>
                        </Table.Row>
                    )}
                    <Table.Row>
                        {tableColumns.map((col, i) => {
                            var headerText = col
                            if(columnLabels) {
                                headerText = _.get(columnLabels, i, false)
                            }
                            var searchInput = false
                            if(search && i === 0) {
                                var searchIcon = (
                                    <Icon
                                        name={searchText.length > 0 ? "close" : "search"}
                                        onPointerDown={() => {
                                            setSearchText("")
                                        }}
                                        link={searchText.length > 0}
                                        circular={searchText.length > 0}
                                        size="small"
                                        style={{
                                            position: "relative",
                                            top: searchText.length > 0 ? 0 : 9
                                        }}
                                        />
                                )
                                searchInput = (
                                    <Input
                                        transparent={true}
                                        style={{
                                            float: "right",
                                            width: 200
                                        }}
                                        icon={searchIcon}
                                        placeholder="search..."
                                        value={searchText}
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                        }}
                                        />
                                )
                            }
                            return (
                                <Table.HeaderCell key={col}
                                    >
                                        <Button
                                            onPointerDown={() => {
                                                if(sortColumn === col) {
                                                    setSortDirection(!sortDirection)
                                                } else {
                                                    setSortColumn(col)
                                                }
                                            }}
                                            basic
                                            size="tiny"
                                            >
                                            {headerText}
                                        </Button>
                                    
                                    {searchInput}
                                </Table.HeaderCell>
                            )
                        })}
                        
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredData.map((row, i) => {
                        var onPointerDown = undefined
                        if(onRowSelect) {
                            onPointerDown = () => {
                                onRowSelect(row, i)
                            }
                        }
                        return (
                            <Table.Row key={`row-${i}`}>
                                {tableColumns.map((col, i) => {
                                    var cellValue = row[col]
                                    if(_.isBoolean(cellValue)) {
                                        cellValue = _.toString(cellValue)
                                    } else if(_.isUndefined(cellValue)) {
                                        cellValue = "undefined"
                                    }
                                    
                                    return (
                                        <Table.Cell
                                            key={`col-${col}`}
                                            onPointerDown={onPointerDown}
                                            >
                                                {_.isFunction(link) && i === 0 ? (
                                                    <a href={link(row)}>
                                                        {cellValue}
                                                    </a>
                                                ) : (
                                                    <React.Fragment>
                                                        {cellValue}
                                                    </React.Fragment>
                                                )}
                                            
                                        </Table.Cell>
                                    )
                                })}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </div>
    )
}

export default DataTable