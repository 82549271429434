import React, { useContext } from 'react'
import VersionsList from '../VersionsList'
import {
    Button
} from 'semantic-ui-react'
import _ from "lodash"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import TagEditor from "../TagEditor"

dayjs.extend(localizedFormat)

const EntitySummary = ({
    entity,
    context
}) => {
    const entityState = useContext(context)
    const versions = _.get(entity, 'versions', false)
    console.log(entity)
    return (
        <div className='entity-summary'>
            <h2>{entity.name}</h2>
            <p>Created at: <i>{dayjs(entity.createdAt).format("llll")}</i>, updated at: <i>{dayjs(entity.updatedAt).format("llll")}</i></p>
            <div style={{marginBottom: 20}}>
                <TagEditor
                    entity={entity}
                    context={context}
                    />
            </div>
            {versions ? (
                <VersionsList actions={entityState.actions} entity={entity} versions={versions} rollbackButton={true} />
            ) : (
                <div>
                    <Button
                        onPointerDown={() => {
                            entityState.actions.getVersions(entity)
                        }}
                        >Get versions</Button>
                </div>
            )}
        </div>
    )
}

export default EntitySummary