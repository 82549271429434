import React, { useState, useContext } from 'react'
import {
    Form,
    Input,
    Button,
    Message
} from 'semantic-ui-react'
import _ from 'lodash'

const DeleteEntityForm = ({
    context,
    entity,
    cancelCallback
}) => {
    const entityState = useContext(context)
    const [confirmName, setConfirmName] = useState('')
    var inputClass = ''
    let icon = false
    if(confirmName == entity.name) {
        inputClass += ' names-match'
        icon = 'check'
    }
    const deleting = _.get(entity, 'deleting', false)

    

    return (
        <div className='delete-entity-form'>
            {entity == false && entityState.recentlyDeleted ? (
                <p>Successfuly deleted {entityState.entityName} <i>{entityState.recentlyDeleted.name}</i>!</p>
            ) : (
                <React.Fragment>
                    <p>To confirm deletion, type the name of the {entityState.entityName} <strong>{entity.name}</strong></p>
                    <div className='ui form'>
                        <Form.Field>
                            <label>Type name to confirm</label>
                            <Input
                                placeholder='name...'
                                value={confirmName}
                                className={inputClass}
                                onChange={(e) => setConfirmName(e.target.value)}
                                icon={icon}
                                />
                        </Form.Field>
                        {cancelCallback && (
                            <Button
                                onClick={() => {
                                    cancelCallback()
                                }}
                                >
                                Cancel
                            </Button>
                        )}
                        
                        <Button
                            onClick={() => {
                                if(confirmName == entity.name) {
                                    entityState.actions.delete(entity)
                                }
                            }}
                            disabled={confirmName !== entity.name}
                            color={"red"}
                            >
                                Delete
                        </Button>
                        {deleting && (
                            <p>Deleting entity</p>
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

DeleteEntityForm.defaultProps = {
    cancelCallback: false
}

export default DeleteEntityForm