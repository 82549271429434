import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import _ArtworkPage from "./components/ArtworkPage"
import _CollectionPage from "./components/CollectionPage"
import _generatePreview from "./utils/generatePreview"
import _generateCollectionPreview from "./utils/generateCollectionPreview"
import _GridLayout from "./components/GridLayout"
import _MediaViewer from "./components/MediaViewer"

export const ArtworkPage = _ArtworkPage
export const CollectionPage = _CollectionPage
export const GridLayout = _GridLayout
export const MediaViewer = _MediaViewer

export function renderArtworkPage(domId) {
  var containerElement = document.getElementById(domId)
  var artworkId = containerElement.getAttribute("artworkId")
  if(typeof artworkId === 'string' || artworkId instanceof String) {
    ReactDOM.hydrate(
      <React.StrictMode>
        <ArtworkPage artworkId={artworkId} />
      </React.StrictMode>
    , containerElement)
  } else {
    console.warn("container element attr: artworkId, is not provided or is not a string.")
  }
}

export function renderCollectionPage(domId) {
  var containerElement = document.getElementById(domId)
  var collectionId = containerElement.getAttribute("collectionId")
  if(typeof collectionId === 'string' || collectionId instanceof String) {
    ReactDOM.hydrate(
      <React.StrictMode>
        <CollectionPage collectionId={collectionId} />
      </React.StrictMode>
    , containerElement)
  } else {
    console.warn("container element attr: collectionId, is not provided or is not a string.")
  }
}

export const generatePreview = _generatePreview
export const generateCollectionPreview = _generateCollectionPreview

window.renderArtworkPage = renderArtworkPage
window.renderCollectionPage = renderCollectionPage
window.generatePreview = generatePreview
window.generateCollectionPreview = generateCollectionPreview

console.log("Hyperobjects art ready: ", window)
