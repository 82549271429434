import React from "react"
import _ from "lodash"
import {Image} from "semantic-ui-react"

const MediaViewer = ({
    url = "",
    props = {}
}) => {
    if(url.endsWith(".mp4")) {
        const width = _.get(props, "width", "100%")
        const height = _.get(props, "height", "100%")
        const loop = _.get(props, "loop", true)
        const muted = _.get(props, "muted", true)
        const autoPlay = _.get(props, "autoPlay", true)
        return (
            <video width={width} height={height} muted={muted} autoPlay={autoPlay} loop={loop}>
                <source src={url} type="video/mp4"></source>
            </video>
        )
    }
    return (
        <Image
            src={url}
            fluid
            {...props}
            />
    )
}

export default MediaViewer