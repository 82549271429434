import types from './actionTypes'

export const useActions = (dispatch) => {
    return {
        userLogIn: user => {
            dispatch({
                type: types.USER_LOG_IN,
                payload: user
            })
        },
        userLogOut: () => {
            dispatch({
                type: types.USER_LOG_OUT
            })
        },
        checkAuth: (user) => {
            dispatch({
                type: types.USER_CHECK_AUTH,
                payload: user
            })
        },
        refreshToken: user => {
            dispatch({
                type: types.USER_REFRESH_TOKEN,
                payload: user
            })
        },
        checkToken: () => {
            dispatch({
                type: types.USER_CHECK_TOKEN
            })
        },
        createAccount: (user) => {
            dispatch({
                type: types.USER_CREATE_ACCOUNT,
                payload: user
            })
        }
    }
}