import React, { useState } from "react"
import {
    Button,
    Container
} from "semantic-ui-react"
import _ from "lodash"
import ListLayout from "./Layouts/List"
import GridLayout from "../GridLayout"


const viewTypes = {
    grid: "grid",
    table: "table",
    list: "list"
}

const CollectionPageContent = ({ collection }) => {
    const [view, setView] = useState(viewTypes.list)
    const items = _.get(collection, "cachedObjects", [])
    var gridLayouts = _.get(collection, "gridLayouts", false)
    var gridItems = []
    var layouts = false
    if(_.isObject(gridLayouts) && _.has(gridLayouts, 'items') && _.has(gridLayouts, "layouts")) {
        gridItems = gridLayouts.items
        layouts = gridLayouts.layouts
    } else {
        gridItems = items.map((item) => {
            return {
                id: item._id,
                name: item.name,
                image: _.get(item, "imageUrls[0]", ""),
                link: _.get(item, "pageLink", "")
            }
        })
    }
    return (
        <div className='collection-page-content'>
            <Container>
                <div className='page-layout-controls'>
                    <Button.Group floated="right" basic color="black" size="mini" >
                        <Button toggle active={view===viewTypes.grid} onPointerDown={() => setView(viewTypes.grid)} icon="grid layout" />
                        <Button toggle active={view===viewTypes.list} onPointerDown={() => setView(viewTypes.list)} icon="list" />
                    </Button.Group>
                </div>
            </Container>
            {view === viewTypes.list && (
                <Container>
                <ListLayout items={items} />
                </Container>
            )}
            {view === viewTypes.grid && (
                <Container>
                <GridLayout
                    items={gridItems}
                    layouts={layouts}
                    />
                </Container>
            )}
           
        </div>
    )

}

export default CollectionPageContent