import types from './actionTypes'
import jwt_decode from "jwt-decode";
import _ from 'lodash'

import {
    login,
    checkAuth,
    refreshToken,
    checkToken,
    logOut,
    createAccount
} from './util/auth'

export const applyMiddleware = dispatch => action => {
    switch(action.type) {
        case types.USER_CREATE_ACCOUNT:
            createAccount(action.payload)
                .then(resp => {
                    if(resp.status === 200) {
                        console.log('success!')
                        resp.json().then(user => {
                            var decodedHeader = jwt_decode(user.token)
                            dispatch({
                                type: types.USER_CREATE_ACCOUNT_CONFIRMED,
                                payload: {
                                    ...user,
                                    ...decodedHeader
                                }
                            })
                        }).catch(json_parse_error => {
                            console.log(json_parse_error)
                        })
                    } else if(resp.status === 404) {
                        dispatch({
                            type: types.USER_CREATE_ACCOUNT_ERROR,
                            payload: `404 ${resp.statusText}`
                        })
                    } else if(resp.status === 400) {
                        resp.json().then(errorMessages => {
                            console.log(errorMessages)
                            var messages = _.get(errorMessages, 'messages', false)
                            if(messages === false) {
                                messages = _.get(errorMessages, 'message', false)
                            }
                            if(messages === false) {
                                messages = 'Unknown error response'
                            }
                            dispatch({
                                type: types.USER_CREATE_ACCOUNT_ERROR,
                                payload: messages
                            })
                        })
                    } else {
                        dispatch({
                            type: types.USER_CREATE_ACCOUNT_ERROR,
                            payload: `${resp.status} ${resp.statusText}`
                        })
                    }
                }).catch(err => {
                    console.log('general error: ', err)
                    dispatch({
                        type: types.USER_CREATE_ACCOUNT_ERROR,
                        payload: "request failed"
                    })
                })

        case types.USER_LOG_IN:
            login(action.payload)
                .then(resp => {
                    if(resp.status === 200) {
                        resp.json().then(user => {
                            var decodedHeader = jwt_decode(user.token)
                            dispatch({
                                type: types.USER_LOG_IN_CONFIRMED,
                                payload: {
                                    ...user,
                                    ...decodedHeader
                                }
                            })
                        })
                    }
                })
            dispatch(action)
            break
        case types.USER_LOG_OUT:
            logOut()
                .then(resp => {
                    console.log(resp)
                })
            dispatch(action)
            break
        case types.USER_CHECK_AUTH:
            checkAuth(action.payload)
                .then(resp => {
                    if(resp.status === 200) {
                        resp.json().then(() => {
                            dispatch({
                                type: types.USER_CHECK_AUTH_SUCCESSFUL
                            })
                        })
                    } else {
                        dispatch({
                            type: types.USER_CHECK_AUTH_ERROR,
                            payload: `${_.get(resp, 'status')}, - ${_.get(resp, 'statusText')}`
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    dispatch({
                        type: types.USER_CHECK_AUTH_ERROR
                    })
                })

            dispatch(action)
            break
        case types.USER_REFRESH_TOKEN:
            refreshToken(action.payload)
                .then(resp => {
                    if(resp.status === 200) {
                        resp.json().then(user => {
                            var decodedHeader = jwt_decode(user.token)
                            dispatch({
                                type: types.USER_REFRESH_TOKEN_SUCCESSFUL,
                                payload: {
                                    ...user,
                                    ...decodedHeader
                                }
                            })
                        })
                    } else {
                        dispatch({
                            type: types.USER_REFRESH_TOKEN_ERROR
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: types.USER_REFRESH_TOKEN_ERROR
                    })
                })
            dispatch(action)
            break
        case types.USER_CHECK_TOKEN:
            checkToken()
                .then(resp => {
                    if(resp.status === 200) {
                        resp.json().then(user => {
                            if(user.message === 'token verified') {
                                var decodedHeader = jwt_decode(user.token)
                                dispatch({
                                    type: types.USER_CHECK_TOKEN_SUCCESSFUL,
                                    payload: decodedHeader
                                })
                            }
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            dispatch(action)
            break
        default: dispatch(action)
    }
}